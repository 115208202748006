.advanced-table-wrapper {
  width: 100%;
  overflow-x: hidden;

  .advanced-table-row:hover {
    cursor: pointer;
    background-color: #afafaf;
  }

  .fit-number {
    width: max-content;
  }

  .MuiTableCell-head {
    color: #193150;
    border-bottom-color: #193150;
    border-bottom-width: 1px;
  }

  .MuiTableCell-body {
    color: #707070;
  }

  .MuiTableCell-root {
    //line-height: 1;
    //padding: 0.5em 0.6em;
  }

  .MuiTableHead-root {
    position: relative;

    .progress-row {
      td {
        height: 4px;
      }
    }
  }

  &.loading {
    .MuiTableCell-body {
      color: rgba(112, 112, 112, 0.8);
    }

    // Also dimm the chips and icons
    .MuiChip-root, .MuiSvgIcon-root {
      opacity: .4;
    }
  }
}
