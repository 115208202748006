@import "src/style/colors";

.sit-page, .initiative-page, .target-page, .financial-dashboard-page, .pipeline-dashboard-page {

  .MuiLink-root {
    cursor: pointer;
  }

  .MuiTypography-h1, h1.MuiTypography-h1 {
    font-weight: 400;
    font-size: 36px;
    line-height: 1;
  }

  .MuiTypography-h3, h3.MuiTypography-h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    margin-top: 2em;
  }

  .MuiFormControl-root {
    //margin-top: .5em;
  }

  .MuiTypography-root.subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
  }

  .MuiTypography-root.koi-value {
    margin-top: .5em;
    font-weight: 700;
    font-size: 32px;
    line-height: 1;
  }

  section.sit-page-section {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .initiative-table-component {
    .initiative-filters-component {
      margin-bottom: 2em;
    }
  }

  .target-table-component {
    .target-filters-component {
      margin-bottom: 2em;
    }
  }

  .financial-dashboard-page {
    .financial-filters-component {
      margin-bottom: 2em;
    }
  }

  .MuiButton-root.loading-button {
    .MuiButton-startIcon {
      width: 20px;
    }
  }
}

.sit-page {
  .MuiAutocomplete-root:not(.Mui-focused) {
    .MuiAutocomplete-tag.MuiChip-root {
      width: calc(100% - 60px);
    }

    span.MuiAutocomplete-tag {
      font-size: small;
      width: 10px;
      margin-left: 0;
      margin-right: 0;
    }

    input {
      width: 30px;
      min-width: 30px;
    }
  }
}

.financial-dashboard-page, .pipeline-dashboard-page {
  .dashboard-title {
    color: $colorDashboard;
    margin-bottom: 3em;
  }
}

.target-vs-actual-table {
  margin-bottom: 4em;
}